import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const SENTRY_DSN = isLocalhost
  ? undefined
  : 'https://158815a9485c4f5f94d9fbcc2722d235@o4504889817235456.ingest.sentry.io/4504889820512256';

Sentry.init({
  dsn: SENTRY_DSN,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay(),
    new CaptureConsole({ levels: ['error'] }),
  ],
  tracesSampleRate: 0.5,
  environment: import.meta.env.MODE,
});
